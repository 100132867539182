import {ChangeDetectorRef, inject, Injectable, Input, OnInit, signal} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Observable} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';
import {objectTreeSelect, removeEmptyProperties} from '@shared/utils/object.utils';
import {ToastService} from '@core/services/toast.service';
import {IErrorResponse} from '@core/types/response.interface';
import {IFormField, IFormFields} from '@core/interfaces/basic-form.interface';
import {DefaultService} from '@core/services/default.service';
import {ModalService} from '@core/services/modal.service';

@Injectable()
export abstract class BasicForm implements OnInit {
  public abstract form: FormGroup;
  public abstract _defaultService: DefaultService;
  public _toastService = inject(ToastService);
  public isPending = signal(false);
  public _modalService = inject(ModalService);
  public showSkeleton = signal(false);

  public isEdit = false;
  @Input() selectedRow: any;
  protected _fb = inject(FormBuilder);
  private cdr = inject(ChangeDetectorRef);

  ngOnInit(): void {
    if (this.isEdit && this.selectedRow && this.getSelector()) {
      this.loadData();
    }
  }

  beforeSubmit() {
    this.isPending.set(true);
  }

  closeModal() {
    this._modalService.close();
    this.form.reset();
  }

  convertToRequestData(formValue: any) {
    return removeEmptyProperties(formValue);
  }

  submitAction(data: object): Observable<any> {
    return this.isEdit ? this._defaultService.update(data, this.getSelector()) : this._defaultService.insert(data);
  }

  onSubmit() {
    this.form.markAllAsTouched();

    if (this.form.valid && !this.isPending()) {
      this.beforeSubmit();
      const data = this.convertToRequestData(this.form.value);

      this.submitAction(data)
        .subscribe({
          next: (res) => this.afterSuccessSubmit(res),
          error: (res: HttpErrorResponse) => this.afterErrorSubmit(res),
        });
    }
  }

  afterSuccessSubmit(res?: any) {
    this.isPending.set(false);
    setTimeout(() => {
      this._modalService.close();
      this.form.reset();
    }, 500);

    if (res?.message && res?.success === true || res?.success === false) {
      this.showResponseStatus(res.success, 100, res?.message);
    } else if (res?.status && res?.message) {
      this.showResponseStatus(false, res.status, res?.message);
    } else {
      this._toastService.warning('ResDataInvalid');
    }
  }

  afterErrorSubmit(res: HttpErrorResponse) {
    this.isPending.set(false);
    this.setResponseErrors(res.error);
  }


  showResponseStatus(success: boolean, status: number, message: string) {
    if (success === true || success === false) {
      success ? this._toastService.success(message) : this._toastService.error(message);
    } else if (status && status !== 100) {
      this._toastService.warning(message + '\n' + status);
    }

  }

  patchResponseData(res: any) {
    this.form.patchValue(res);
  }

  getSelector() {
    return this.selectedRow[this._defaultService.selectedRowDataToSelect];
  }

  loadData() {
    this.showSkeleton.set(true);
    this.form.reset();
    this._defaultService
      .getOne(this.getSelector())
      .subscribe((res: any) => {
        this.patchResponseData(res);
        this.showSkeleton.set(false);
      });
  }

  protected isFormField(formField: IFormField | IFormFields) {
    return Boolean(formField.isFormField);
  }

  protected setResponseErrors(errorResponse: IErrorResponse) {
    if (errorResponse.errors) {
      for (let errorKey in errorResponse.errors) {
        const select: FormControl | null = objectTreeSelect(this.form.controls, errorKey);
        if (select) {
          select.setErrors({error: errorResponse.errors[errorKey][0]});
          select.markAsTouched();
          this.cdr.markForCheck();
        }
      }
    }
  }
}
